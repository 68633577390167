<template>
    <v-container fluid>  
        <TheTitle :text="title" :icon="pageIcon">
            <v-row justify="center">
                <v-col md="8" sm="10" cols="10">
                    <v-text-field
                    placeholder="Procurar"
                    dense
                    solo
                    background-color="#FFFFFF"
                    hide-details
                    v-model="search"            
                    ></v-text-field>
                </v-col>
                <v-col md="4" sm="4" cols="4">
                    <v-btn :disabled="isDisabled" block color="amber darken-4" dark @click="dialogSave()">
                        <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
                    </v-btn>
                    <v-btn class="mt-3" block color="success" dark @click="$router.back()">
                        <v-icon small class="mr-2">mdi-arrow-left</v-icon> Voltar
                    </v-btn>                    
                </v-col>
            </v-row>
        </TheTitle>

        <v-card class="pa-5">
            <v-row>
                <v-col md="2" sm="12" cols="12">
                    <b>COD</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.id"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="5" sm="12" cols="12">
                    <b>Titulo</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.title"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="5" sm="12" cols="12">
                    <b>Responsavel</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        :value="getPersonName(objects.idResponsible)"
                        class="my-2"
                    ></v-text-field>
                </v-col>                
            </v-row>
            <v-row>
                <v-col md="2" sm="12" cols="12">
                    <b>Data de Abertura</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.startDate"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="2" sm="12" cols="12">
                    <b>Data de Fechamento</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.endDate"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="2" sm="12" cols="12">
                    <b>Risco</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.risk"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="3" sm="12" cols="12">
                    <b>Status</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.status"
                        class="my-2"
                    ></v-text-field>
                </v-col>                
                <v-col md="3" sm="12" cols="12">
                    <b>Valor Total do Recurso</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="total"
                        class="my-2 font-weight-black"
                    ></v-text-field>
                </v-col>                
            </v-row>
        </v-card>

        <v-card class="pa-5 mt-5 full-height">
            <v-data-table
                :headers="header"
                :items="resource"
                dense
                locale="pt-BR"
                :search="search"
                :items-per-page="15"
            >
                <template v-slot:item.budget="{ item }">
                    {{ item.budget | formatMoneyS1 }}
                </template>
                                
                <template v-slot:[`item.actions`]="{ item }">                    
                    <TheToolTip label="Vizualizar" v-if="item.status == 'Finalizado'">
                        <v-btn icon small tile >
                            <v-icon @click="dialogView1(item)">mdi mdi-file-eye-outline</v-icon>
                        </v-btn>
                    </TheToolTip>
                    <TheToolTip label="Editar" v-if="item.status !== 'Finalizado'">
                        <v-btn :disabled="item.status === 'Finalizado' || objects.status === 'Finalizado'" icon small tile >
                            <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
                        </v-btn>
                    </TheToolTip>
                    <TheToolTip label="Remover" v-if="item.status !== 'Finalizado'">
                        <v-btn icon small tile >
                            <v-icon :disabled="item.status === 'Finalizado' || objects.status === 'Finalizado'" @click="remove(item.id)">mdi-minus</v-icon>
                        </v-btn>
                    </TheToolTip>
                </template>
                
            </v-data-table>
        </v-card>

        <TheDialog v-model="dialog" :title="title" :icon="pageIcon" @close-dialog="loadData" width="800px">
            <template>
                <v-form v-model="valid" ref="form1">
                    <v-row>
                        <v-col md="12" sm="12" cols="12">
                            <b>Tipo de Recurso *</b>
                            <v-select
                                :items="list"
                                v-model="object.typeResource"
                                class="my-2"
                                dense
                                tile
                                outlined
                                color="primary"
                                :rules="[requiredRule, spaceRule]"
                            ></v-select>
                        </v-col>
                    </v-row>
                    
                    <v-row class="mt-1">
                        <v-col md="12" sm="12" cols="12" v-if="object.typeResource == 'Ferramentas e Tecnologia'">
                            <b> Ferramentas e Tecnologia </b>
                            <v-select
                                :items="tools"
                                v-model="object.resource"
                                placeholder="Selecione"
                                outlined
                                tile
                                dense
                                class="mt-2"
                                color="primary"
                                :rules="[requiredRule, spaceRule]"
                            >
                            </v-select>
                        </v-col>   

                        <v-col md="12" sm="12" cols="12" v-if="object.typeResource == 'Equipes'">
                            <b> Equipes Necessárias </b>
                            <v-select
                                :items="teams"
                                v-model="object.resource"
                                placeholder="Selecione"
                                outlined
                                tile
                                dense
                                class="mt-2"
                                color="primary"
                                :rules="[requiredRule, spaceRule]"
                            >
                            </v-select>
                        </v-col>

                        <v-col md="12" sm="12" cols="12" v-if="object.typeResource == 'Infraestrutura Necessária'">
                            <b> Infraestrutura Necessárias </b>
                            <v-select
                                :items="infrastructure"
                                v-model="object.resource"
                                placeholder="Selecione"
                                outlined
                                tile
                                dense
                                class="mt-2"
                                color="primary"
                                :rules="[requiredRule, spaceRule]"
                            >
                            </v-select>
                        </v-col>

                    </v-row>    

                    <v-divider class="my-2"></v-divider>

                    <v-row class="mt-1">                        
                        <v-col md="12" sm="12" cols="12">
                            <b>Orçamento *</b>
                            <v-text-field
                                :rules="[requiredRule, spaceRule]"
                                v-model="object.budget"
                                v-money="moneyConfig"
                                outlined
                                tile
                                dense
                                class="my-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>                    

                    <v-row>
                        <v-col cols="12">
                            <b> Descrição *</b>
                            <v-textarea
                                outlined
                                rows="3"
                                v-model="object.description"
                                class="my-2"
                                auto-grow
                                :rules="[requiredRule, spaceRule]"
                            ></v-textarea>
                        </v-col>
                    </v-row>

                </v-form>
            </template>
            <template v-slot:actions="">
                <v-btn
                    small
                    depressed
                    color="primary"                 
                    @click="resolveForm()"
                >
                    <span v-if="!edit"><v-icon small class="mr-2">mdi-plus</v-icon>Adicionar</span>
                    <span v-if="edit" ><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
                </v-btn>
                <v-spacer></v-spacer>
                <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
            </template>
        </TheDialog>      

    </v-container>
</template>


<script>
    import TheTitle from "@/components/TheTitle"
    import TheDialog from "@/components/TheDialog"
    import TheToolTip from "@/components/TheToolTip"
    import { mask } from "vue-the-mask"
    import { baseApiUrl, showError } from "@/global"
    import axios from "axios"
    import moment from 'moment'
  
    export default {
    
        name: "PlanoAcaoRecurso",
        directives: { mask },
        components: {
            TheTitle,
            TheDialog,
            TheToolTip,
        },
        
        data: () => ({
            
            baseRoute: "security-plan",
            title: "Recursos do Plano de Ação",
            pageIcon: "mdi mdi-currency-usd",

            objects: [],
            object: {},
            people:[],
            resource:[],

            dialog: false,
            search: null,
            valid: false,
            error: false,
            edit: false,
            loading: false,
            isDisabled: false,

            header: [
                {text: 'COD', value: 'id'},
                { text: "Tipo de Recurso", value: "typeResource"},
                { text: "Recurso", value: "resource"},
                { text: "Orçamento", value: "budget"},
                { text: "Ações", value: "actions", width: "10%" },
            ],

            moneyConfig: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
            },

            list: [
                {text: 'Ferramentas e Tecnologia', value: 'Ferramentas e Tecnologia'},
                {text: 'Equipes', value: 'Equipes'},
                {text: 'Infraestrutura Necessária', value: 'Infraestrutura Necessária'},
            ],

            tools: [
                {text: 'Firewall de rede', value: 'Firewall de rede'},
                {text: 'Sistema de detecção de intrusões (IDS)', value: 'Sistema de detecção de intrusões (IDS)'},
                {text: 'Sistema de prevenção de intrusões (IPS)', value: 'Sistema de prevenção de intrusões (IPS)'},
                {text: 'Antivírus/Antimalware', value: 'Antivírus/Antimalware'},
                {text: 'Software de criptografia de dados', value: 'Software de criptografia de dados'},
                {text: 'VPN', value: 'VPN'},
                {text: 'Software de gerenciamento de senhas', value: 'Software de gerenciamento de senhas'},
                {text: 'Ferramentas de monitoramento de tráfego de rede', value: 'Ferramentas de monitoramento de tráfego de rede'},
                {text: 'Ferramentas de análise de vulnerabilidades', value: 'Ferramentas de análise de vulnerabilidades'},
                {text: 'Autenticação de dois fatores (2FA)', value: 'Autenticação de dois fatores (2FA)'},
                {text: 'Controle de acesso baseado em função (RBAC)', value: 'Controle de acesso baseado em função (RBAC)'},
                {text: 'Certificados SSL/TLS', value: 'Certificados SSL/TLS'},
                {text: 'Proteção contra ataques de negação de serviço (DDoS)', value: 'Proteção contra ataques de negação de serviço (DDoS)'},
                {text: 'Proteção de endpoint (Endpoint Protection)', value: 'Proteção de endpoint (Endpoint Protection)'},
            ],

            teams: [
                {text: 'Especialista em Segurança da Informação', value: 'Especialista em Segurança da Informação'},
                {text: 'Analista de Segurança Cibernética', value: 'Analista de Segurança Cibernética'},
                {text: 'Analista de Dados', value: 'Analista de Dados'},
                {text: 'Desenvolvedor de Software', value: 'Desenvolvedor de Software'},
                {text: 'Administrador de Sistemas', value: 'Administrador de Sistemas'},
                {text: 'Administrador de Banco de Dados', value: 'Administrador de Banco de Dados'},
                {text: 'Especialista em Criptografia', value: 'Especialista em Criptografia'},
                {text: 'Engenheiro de Rede', value: 'Engenheiro de Rede'},
                {text: 'Especialista em Conformidade e LGPD', value: 'Especialista em Conformidade e LGPD'},
                {text: 'Analista de Resposta a Incidentes de Segurança', value: 'Analista de Resposta a Incidentes de Segurança'},
                {text: 'Especialista em Gerenciamento de Riscos', value: 'Especialista em Gerenciamento de Riscos'},
                {text: 'Especialista em Conscientização em Segurança', value: 'Especialista em Conscientização em Segurança'},
                {text: 'Especialista em Privacidade e Proteção de Dados', value: 'Especialista em Privacidade e Proteção de Dados'},
                {text: 'Especialista em Monitoramento de Segurança', value: 'Especialista em Monitoramento de Segurança'},
            ],
            
            infrastructure: [
                {text: 'Atualização de Hardware', value: 'Atualização de Hardware'},
                {text: 'Aquisição de Servidores Dedicados', value: 'Aquisição de Servidores Dedicados'},
                {text: 'Implementação de Sistemas de Backup Adicionais', value: 'Implementação de Sistemas de Backup Adicionais'},
                {text: 'Ampliação da Capacidade de Armazenamento', value: 'Ampliação da Capacidade de Armazenamento'},
                {text: 'Aquisição de Equipamentos de Rede', value: 'Aquisição de Equipamentos de Rede'},
                {text: 'Melhoria da Segurança Física', value: 'Melhoria da Segurança Física'},
                {text: 'Migração para a Nuvem', value: 'Migração para a Nuvem'},
                {text: 'Atualização de Software', value: 'Atualização de Software'},
                {text: 'Aquisição de Ferramentas de Monitoramento', value: 'Aquisição de Ferramentas de Monitoramento'},
                {text: 'Implementação de Sistemas de Redundância', value: 'Implementação de Sistemas de Redundância'},
            ],            
        }),

        computed: {
            total() {
                if (Array.isArray(this.resource)) {
                    const totalValue = this.resource.reduce((acumulador, item) => acumulador + parseFloat(item.budget), 0);
                    return 'R$ ' + totalValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                }
                return 'R$ 0,00';
            },
            getPersonName() {
                return function (idResponsible) {
                    const person = this.people.find((el) => el.id === idResponsible);
                    if (person) {
                        return person.name;
                    } else {
                        return "";
                    }
                };
            },
        
        },

        methods: {  

            loadData() {
                this.$store.dispatch("setLoadingInfo", true)
                const url = `${baseApiUrl}/${this.baseRoute}/search/${this.$route.params.id}/security-plan-resource`
                axios
                    .get(url)
                    .then((res) => {                        
                        
                        this.$store.dispatch("setLoadingInfo", false)
                        this.resource = res.data                                                
                    })
                    .catch(showError)
            }, 

            async loadPerson() {
                const url = `${baseApiUrl}/people`;
                const res = await axios.get(url);
                this.people = res.data;
            },

            async loadItem() {
                const id = this.$route.params.id

                this.$store.dispatch("setLoadingInfo", true)
                const url = `${baseApiUrl}/${this.baseRoute}/${id}`

                try {
                    const res = await axios.get(url)
                    this.$store.dispatch("setLoadingInfo", false)
                    this.objects = res.data

                    this.objects.startDate = moment(this.objects.startDate).format('DD/MM/YYYY')
                    this.objects.endDate = moment(this.objects.endDate).format('DD/MM/YYYY')

                    if(this.objects.status == 'Em andamento' || this.objects.status == 'Pendente'){
                        delete this.objects.endDate
                        this.objects.endDate = "Em Aberto"
                    }

                    if(this.objects.status == "Finalizado"){
                        this.isDisabled = true;
                    }
                } catch (error) {
                    showError(error);
                }
            },
            
            dialogSave() {
                this.object = {}
                this.edit = false
                this.dialog = true
            },

            dialogUpdate(item) {
                this.object = item;
                this.edit = true
                this.dialog = true         
            },

            resolveForm(){
                this.$refs.form1.validate()
                if (!this.valid){
                    this.$store.dispatch("setErrorInfo", {
                        open: true,
                        text: "Os campos não foram preenchidos corretamente, você pode verificar?",
                        button: "Ok!",
                    })
                    return
                }
                if(this.edit){
                    this.update()
                } else {
                    this.save()
                }
            },           

            
            save(){
                this.error = false
                const url = `${baseApiUrl}/security-plan-resource`
                const objects = this.object  
                objects.idPlanSecurity = this.objects.id
                
                const valorFormatado = this.object.budget.replace(/[R$\s]/g, '').replace('.', '').replace(',', '.');
                objects.budget = parseFloat(valorFormatado);
                
                axios
                    .post(url, objects)
                    .then(() => this.loadData())
                    .catch(showError)
                    .finally(() => {
                        this.dialog = false
                    })    
                    
                this.loadData()
            },

            update() {
                this.error = false
                const id = this.object.id
                const url = `${baseApiUrl}/security-plan-resource/${id}`
                const objects = this.object

                const valorFormatado = this.object.budget.replace(/[R$\s]/g, '').replace('.', '').replace(',', '.');
                objects.budget = parseFloat(valorFormatado);

                delete objects.id

                delete objects.createdAt
                delete objects.updatedAt
                delete objects.deletedAt
                
                axios
                    .put(url, objects)
                    .then(() => this.loadData())
                    .catch(showError)
                    .finally(() => {
                        this.dialog = false
                    })
            },
                            
            remove(id) {
                if (confirm("Remove?")) {
                    const url = `${baseApiUrl}/security-plan-resource/${id}` 
                    axios
                    .delete(url)
                    .then(() => this.loadData())
                    .catch(showError)
                }
            },

            getStatusColor(status) {
                if (status == "Em andamento") {
                    return "#FBC02D"
                }
                else if (status == "Finalizado") {
                    return "#4CAF50"
                }
                else if (status == "Pendente") {
                    return "#757575" 
                }
            },
        },
        mounted() {
            this.loadPerson()
            this.loadData()
            this.loadItem()
        }
    }
</script>

<style>
.full-height {
  height: 80vh;
}
</style>